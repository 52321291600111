import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { AvailableIcons, ColorsIcons } from '../types';

@Component
class IconMixin extends Vue {
  @Prop() icon!: AvailableIcons;
  @Prop() color!: ColorsIcons;
  @Prop({ required: false, default: false }) is_hovered?: boolean; // only relevant for plus
  @Prop({ required: false, default: 0 }) width?: number;
  @Prop({ required: false, default: 0 }) height?: number;
  get finalWidth (): string {
    if (this.width) { return this.width.toString(); }
    if (this.height) {
      return '100%';
    } else {
      return '50';
    }
  }
  get finalHeight (): string {
    if (this.height) { return this.height.toString(); }
    if (this.width) {
      return '100%';
    } else {
      return '50';
    }
  }
}
export default IconMixin;
