













import { Component, mixins } from 'nuxt-property-decorator';
import IconMixin from '../../../mixins/IconMixin';

@Component
export default class IconHandler extends mixins(IconMixin) {
  get iconComponentName (): string {
    return this.icon.charAt(0).toUpperCase() + this.icon.slice(1);
  }
}
