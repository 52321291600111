















import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokBlogStacked } from '../../types';

@Component
export default class BlogStacked extends Vue {
  @Prop() blok!: BlokBlogStacked;
  get selection (): string[] {
    return this.blok.selection.length ? this.blok.selection : [];
  }
}
