import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { ContentPageBlog, StoryblokAsset, StoryblokStory } from '../types';
import { getBlog } from '../store';

@Component
class BlogTeaserMixin extends Vue {
  @Prop() uuid!: string;
  get entry (): StoryblokStory|false {
    return getBlog(this.$store).entryByUuid(this.uuid);
  }
  get post (): ContentPageBlog|false {
    if (!this.entry) { return false; }
    return this.entry.content;
  }
  get media (): StoryblokAsset | false {
    if (!this.post) { return false; }
    if (this.post.thumbnail_teaser && this.post.thumbnail_teaser.filename) {
      return this.post.thumbnail_teaser;
    }
    if (this.post.thumbnail && this.post.thumbnail.filename) {
      return this.post.thumbnail;
    }
    return false;
  }
  get date (): string|false {
    if (!this.post) { return false; }
    // @ts-ignore
    return this.post.date_publication ? this.$minifiedDate(this.post.date_publication) || false : false;
  }
  get info (): string {
    if (!this.post) { return ''; }
    const infos: (string|false)[] = [
      this.date,
      // this.post.author || false,
      this.post.reading_duration ? `${this.post.reading_duration} Minuten` : false
    ];
    let info: string = '';
    infos.forEach((information) => {
      if (information) {
        info += `${information} | `;
      }
    });
    // @ts-ignore
    return info.slice(0, info.length - 3);
  }
  get title (): string|false {
    if (!this.post) { return false; }
    return this.post.title.replace('<br>', ' ');
  }
}
export default BlogTeaserMixin;
