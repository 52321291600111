




































import { Component, mixins, Prop } from 'nuxt-property-decorator';
import BlogTeaserMixin from '../../../mixins/BlogTeaserMixin';
import BreakpointMixin from '../../../mixins/BreakpointMixin';
import LinkManual from '@/components/utilities/links/LinkManual';
import MediaHandler from '@/components/utilities/media/MediaHandler';
import IconHandler from '@/components/utilities/icons/IconHandler';

@Component({
  components: { LinkManual, MediaHandler, IconHandler }
})
export default class BlogParallelTeaser extends mixins(BlogTeaserMixin, BreakpointMixin) {
  @Prop() reversed!: boolean;
  is_hovered: boolean = false;
  // -> GSAP animation
  $refs: { container: HTMLElement };
  animate (): void {
    this.$gsap.to(this.$refs.container, {
      scrollTrigger: {
        trigger: () => this.$refs.container,
        start: this.breakpointUp('md') ? 'top bottom-=70px' : 'top bottom-=30px'
      },
      duration: 1.5,
      ease: 'power2.out',
      y: 0,
      scale: 1,
      opacity: 1
    });
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.container, {
      y: this.breakpointUp('md') ? 80 : 30,
      scale: 1.03,
      opacity: 0.7
    });
    this.animate();
  }
}
